<template>
  <section class="mt-5">
      <div class="container">
        <div class="section-title mb-0">
          <h2 class="title">Frequently Asked Questions</h2>
        </div>
        <div class="row">
          <div class="col">
            <div class="accordion" id="accordion">
              <div class="accordion-item mb-4" v-for="(item, i) in items" :key="`item-${i}`">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button rounded-0"
                    type="button"
                    data-toggle="collapse"
                    :data-target="`#collapse-${i}`"
                    :aria-expanded="i == 0 ? 'true' : 'false'"
                    :aria-controls="`collapse-${i}`"
                  >
                    {{ item.topic }}
                  </button>
                </h2>
                <div
                  :id="`collapse-${i}`"
                  :class="`accordion-collapse border-0 collapse ${i == 0 ? 'show' : ''}`"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="accordion-body">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          topic: 'What are SMS credits?',
          content: 'SMS credits are the units you need to purchase to send SMSes. Please visit our pricing page for more information regarding our bulk SMS packages. Kindly note that your credits do not expire. They remain available in your account for as long as you need them.'
        },
        {
          topic: 'How much does one SMS cost?',
          content: 'The rate of one varies based on the SMS recipients phone network (Safaricom, Airtel, Equitel, Telkom, etc) and the amount topped up. Please visit our pricing page to see prices according to top-up amount and recipient phone networks. Please note that a message may be charged twice or more if the character count exceeds 160 characters.'
        },
        {
          topic: 'How many SMS can I send at a time?',
          content: 'No limit'
        },
        {
          topic: 'How do I top up my account?',
          content: 'Navigate to your Top up button and click on ‘Top Up’ then select your preferred payment option. Credits purchased should reflect immediately you get a confirmation message about the payment. '
        },
        {
          topic: 'Do I get notified when my credits are low?',
          content: 'Yes, you can set up a low balance alert which allows you to receive an email or SMS notifying you when your credits are almost depleted.'
        },
        {
          topic: 'How do I set up an account?',
          content: 'Registering an account is easy and you can do it within 5 minutes or less. Simply go to create account option on the homepage, fill in the required information and click register'
        },
        {
          topic: 'What is the maximum length of an SMS message that one can send on the platform?',
          content: 'A standard SMS message has 160 characters. The maximum length of an SMS you can send on the Safaricom, Telkom and EquiTel networks is 999 and 548 on the Airtel Kenya network.'
        },
        {
          topic: 'Do you charge any monthly charges or start up fees?',
          content: 'Monthly charges vary depending on the service, as for Bulk SMS no monthly charges is required although a start up fee of 8000 KSH for Sender ID request is required. Please visit our pricing page for more information'
        },
        {
          topic: 'What are SMS credits?',
          content: 'SMS credits are the units you need to purchase to send SMSes. Please visit our pricing page for more information regarding our bulk SMS packages. Kindly note that your credits do not expire. They remain available in your account for as long as you need them.'
        },
        {
          topic: 'How much does one SMS cost?',
          content: 'The rate of one varies based on the SMS recipients phone network (Safaricom, Airtel, Equitel, Telkom, etc) and the amount topped up. Please visit our pricing page to see prices according to top-up amount and recipient phone networks. Please note that a message may be charged twice or more if the character count exceeds 160 characters.'
        },
        {
          topic: 'How many SMS can I send at a time?',
          content: 'There is no limit on how many SMS one can send at a time.'
        },
        {
          topic: 'How do I top up my account?',
          content: 'Navigate to your billing tab and click on ‘Top Up’ then select your preferred payment option. Credits purchased should reflect immediately you get a confirmation message about the payment.'
        },
        {
          topic: 'How Do I Send a message?',
          content: 'Our platform allows you to upload your contacts using an excel file which you use to send a message. For more information on how you can use the different features to send a message, see instructions here: Send SMS guideline. '
        },
        {
          topic: 'Do I get notified when my credits are low?',
          content: 'Yes, you can set up a insufficient balance alert which allows you to receive an email or SMS notifying you when your credits are almost depleted.'
        },
        {
          topic: 'What format of mobile numbers should be used when I create a contact list',
          content: 'Use a comma after every number'
        },
        {
          topic: 'Do we offer branding/Sender ID services for SMS?',
          content: 'Yes we do. A Sender ID is an alphanumeric name or numeric that appears on the recipients device as the sender of the SMS. The maximum characters allowed for the Sender ID are 11 with no spaces in between. However, spaces can be filled in with either a hyphen (-) or underscore (_). Please view our pricing page for the cost details. Contact us to facilitate the Sender ID request.'
        },
        {
          topic: 'Can I schedule bulk SMSes to be sent at a later date and can I cancel my schedule?',
          content: 'Yes, it’s easy to schedule your bulk SMS for delivery at a later date & time. Choose to schedule them to be sent only after a specific time and date. You can also cancel your scheduled SMS by navigating to the Outbox tab and clicking on Scheduled Tab then deleting the sms youd like to cancel. Your SMS credits will then be refunded to your account. Contact us for further assistance.'
        },
        {
          topic: 'Can I have more than one sender ID on one account? Are there any additional charges?',
          content: 'Yes, you can have more than one sender ID linked to your account. Every sender ID is charged separately and per the network involved. Networks include and not limited to Safaricom, Telkom and Airtel. Kindly contact us for more information'
        },
        {
          topic: 'How secure is my data?',
          content: 'RIZIKISMS BULK SMS does not share or pass on any data, that includes mobile phone numbers, names, messages, email addresses or any other information you upload to the platform. RIZIKISMS BULK SMS requires a secure password for login credentials. This password should never be shared. See our Terms of Use for further information.'
        },
        {
          topic: 'How do I get replies to messages sent out?',
          content: 'You can achieve this either through the Shortcode or Keyword services. The Shortcode service is a five-digit code assigned exclusively to you. Your recipients can respond to SMS sent using this code and you will be able to view them on your account. These replies will be available on the inbox page. The Keyword service allows you to receive replies through a shared Shortcode (a five-digit code-shared by clients). Your recipients will need to add a unique word at the beginning of their reply to you. Kindly contact us for further details at support@rizikisms.co.ke'
        },
        {
          topic: 'Is there a message delivery report?',
          content: 'yes. A status report appears with every message sent. You can select messages for which you want to view reports by clicking the Outbox tab. We rely on the telephone company to provide delivery reports. The shipping status displayed may differ slightly from the shipping report. Reloading the page may also update the status.'
        }
      ]
    }
  }
}
</script>